<template>
  <div class="content__list">
    <div v-for="(day, index) in days" class="content__item" @drop="onDrop($event, index)"
         @dragenter.prevent @dragover.prevent>
      <div class="task-box">
        <div class="task-box__top">
          <div class="task-box__head">
            <div class="task-box__title" v-html="day.start_date_format"></div>
          </div>
        </div>
        <div class="task-box__main">
          <div :id="index" class="task-box__list dnd-wrap" aria-dropeffect="move" aria-grabbed="false">
            <div v-for="(request, subIndex) in day.requests" class="task-box__item dnd-item" draggable="true"
                 @dragstart="startDrag($event, request, index)"
                 @drop.stop="onDrop($event, index)"
                 @dragover.prevent
                 @dragleave.prevent
                 @dragenter.prevent
                 :class="{'task-deleting': request.parentHidden}"
              >
              <div class="task-item" :class="{'task-item--done': request.hidden}">
                <div class="task-item__extra">
                  <label class="task-item__check">
                    <input @click="changeStatus(index, subIndex)" type="checkbox" v-model="request.hidden">

                    <span class="icon icon--s">
                      <svg class="svg" width="16" height="16">
                        <use :href="'/img/sprite-' + appVersion +'.svg#check-16'"/>
                      </svg>
                    </span>
                  </label>
                </div>
                <button @click="showModal(request.id)" class="task-item__main pu-link">
                  <div class="task-item__info">
                    <div v-if="request.time" class="task-item__time">{{request.time}}</div>
                    <div v-if="request.priority === 'high'" class="task-item__priority task-item__priority--high">
                      <svg class="svg" width="16" height="16">
                        <use :href="'/img/sprite-' + appVersion +'.svg#urgent-16'"/>
                      </svg>
                    </div>
                    <div class="task-item__name">{{request.name}}</div>
                  </div>

                  <div class="task-item__project">
                    <span v-if="request.project.type === 'personal'" class="s-sublink__icon">
                      <svg class="svg-clr" width="20" height="20">
                        <use :href="'/img/sprite-clr-' + appVersion +'.svg#male'"/>
                      </svg>
                    </span>
                    <template v-else>
                      <span v-if="request.project.logo" class="task-item__logo">
                        <img :src="request.project.logo" width="20" height="20" alt="">
                      </span>
                      <template v-else-if="request.project.emoji">{{request.project.emoji}}</template>
                      <span v-else class="s-sublink__logo">
                        <span class="icon icon--m">
                          <svg class="svg" width="24" height="24">
                            <use :href="'/img/sprite-' + appVersion + '.svg#logo-blank-24'" />
                          </svg>
                        </span>
                      </span>
                    </template>
                  </div>
                </button>
              </div>
            </div>
            <div v-if="!day.requests.length && day.start_date" class="task-box__item dnd-item">
              <div class="task-box__item task-box__item--blank">
                <svg class="svg" width="24" height="24">
                  <use :href="'/img/sprite-' + appVersion + '.svg#capybara-24'" />
                </svg>
                Задач на этот день нет</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import packageInfo  from '../../../../package';
import axios from 'axios';

export default {
  props: {
    days: Array
  },
  data() {
    return {
      appVersion: packageInfo.version,
    }
  },
  mounted() {

  },
  methods: {
    changeStatus(index, subIndex) {
      let request = this.days[index].requests[subIndex];

      setTimeout(() => {
        this.days[index].requests[subIndex].parentHidden = true;
      }, 300);

      setTimeout(() => {
        this.days[index].requests.splice(subIndex, 1);
      }, 700);

      setTimeout(() => {
        axios.get('/change-request-diary-status/' + request.id).then((res) => {
          if (this.days[index]) {
            if (this.days[index].requests[subIndex]) {
              this.days[index].requests[subIndex].hidden = false;
              this.days[index].requests[subIndex].parentHidden = false;
            }
          }
        })
      }, 900);
    },
    showModal(id) {
      this.$emit('showModal', id);
    },
    startDrag(event, item, list) {
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('itemId', item.id)
      event.dataTransfer.setData('itemList', list)
    },
    getItemPosition(arr, itemId) {
      return arr.findIndex((item) => item.id == itemId);
    },
    getItemById(event, index) {
      const itemId = +event.dataTransfer.getData('itemId');
      const item = this.days[index].requests.find((item) => item.id == itemId);
      return { item, itemId };
    },
    moveItem(itemList, index, itemPosition, droppedItemPosition, item) {

    },
    mutationArray(from, to, item, itemId) {
      const itemPosition = this.getItemPosition(from, itemId);
      from.splice(itemPosition, 1);
      to.push(item);
    },
    onDrop(event, index) {
      const itemList = +event.dataTransfer.getData('itemList');
      const { item, itemId } = this.getItemById(event, itemList);

      if (item.change_start_date && this.days[index].start_date) {
        this.mutationArray(this.days[itemList].requests, this.days[index].requests, item, itemId);
        this.$emit('changeStartDate', this.days);
        axios.post('/change-request-start-date/' + item.id, {'start_date': this.days[index].start_date}).then((res) => {
        })
      }
    }
  }
};
</script>