<template>
  <div @scroll="handleScroll" class="main__wrap">
    <div class="main__content content">
      <div class="content__top">
        <div class="title">Список каталогов</div>
      </div>
      <div v-if="catalogs.length > 0" class="content__main">
        <div class="content__body">
          <div class="tbl-wrap">
            <table class="tbl">
              <thead>
              <tr>
                <th class="tbl__col tbl__col--grow">Название каталога</th>
                <th>Дата&nbsp;создания</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="catalog in catalogsList">
                <td>
                  <a :href="'/settings/catalogs/' + catalog.id" class="col-main col-link">{{catalog.name}}</a>
                </td>
                <td><span class="col-extra">{{catalog.date}}</span></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div v-if="catalogs.length === 0" class="content__main content__main--center">
        <div class="content__body">
          <div class="plug-box">
            <div class="plug-box__cover">
              <img src="/img/plug-no-tasks.svg" width="574" height="231" alt="" class="plug-box__img">
            </div>

            <div class="plug-box__text">Активных каталогов нет</div>
          </div>
        </div>
      </div>

      <div class="content__bottom">
        <a href="/settings/catalogs/create" class="btn btn--4 btn--m btn--primary" type="button">
          <span class="btn__label">+&nbsp;Добавить каталог</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    catalogs: Array
  },
  data() {
    return {
      catalogsList: this.catalogs,
      page: 1,
      isScrollEnabled: true,
    }
  },
  methods: {
    scrollCatalogs() {
      axios.get('/settings/catalogs?page=' + this.page).then((res) => {
        this.catalogsList = this.catalogsList.concat(res.data);
        this.isScrollEnabled = res.data.length;
      })
    },
    handleScroll(ev) {
      if (this.isScrollEnabled && ev.target.scrollTop >= (ev.target.scrollHeight - ev.target.offsetHeight - 100)) {
        this.page++;
        this.isScrollEnabled = false;
        this.scrollCatalogs();
      }
    }
  }
};
</script>