<template>
  <div class="pu-box pu-box--right pu-box--lg is-open">
    <div class="pu-box__wrap">
      <div class="pu-box__top">
        <div class="pu-task-top">
          <div class="pu-task-top__wrap">
            <div class="pu-task-top__close">
              <button @click="closeModal" class="btn btn--4 btn--m btn--gray btn--icon pu-close" type="button">
                <span class="btn__icon">
                  <span class="icon icon--m">
                    <svg class="svg" width="24" height="24">
                      <use :href="'/img/sprite-' + appVersion + '.svg#cross-24'" />
                    </svg>
                  </span>
                </span>
              </button>
            </div>
            <div class="pu-task-top__info">
              <div class="task-head">
                <span class="task-head__number"><strong>№{{requestId}}</strong></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pu-box__main">
        <div class="pu-box__no-access">
          <div class="blank">
            <div class="blank__cover">
              <svg class="svg" width="80" height="80">
                <use :href="'/img/sprite-' + appVersion + '.svg#lock-80'" />
              </svg>
            </div>
            <div class="blank__text" v-html="error"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import packageInfo  from '../../../../package';

export default {
  props: {
    requestId: Number,
    error: String
  },
  data() {
    return {
      appVersion: packageInfo.version,
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
  }
};
</script>