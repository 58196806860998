<template>
  <div class="main__wrap">
    <form class="main__content content content--wide">
      <div class="content__top">
        <div class="title">Мой профиль</div>
      </div>

      <div class="content__main">
        <div class="content__body">
          <div class="content__block">
            <div class="row row--aife">
              <template v-if="imageToCrop">
                <div class="col">
                  <cropper style="width: 500px" ref="cropper" :stencil-props="{aspectRatio: 1}" :src="imageToCrop"/>
                </div>

                <div class="col">
                  <button type="button" @click="cropSelectedImage" class="btn btn--1 btn--m btn--primary">Сохранить
                  </button>
                </div>
              </template>

              <div class="col">
                <label class="ava-file"><span v-if="dataChanged['photo']" class="q-note q-note--saved">Сохранено</span>
                  <input type="file" @change="uploadPhoto($event)" class="ava-file__input visually-hidden"
                         placeholder="Добавить файлы"
                         ref="upload-photo" accept=".png,.jpg,.jpeg,.bmp">
                  <div class="ava-file__main">
                    <span class="ava-file__cover">
                      <span class="icon icon--m">
                        <svg class="svg" width="24" height="24">
                          <use :href="'/img/sprite-' + appVersion + '.svg#camera-24'"/>
                        </svg>
                      </span>
                      <img :src="photoUrl" v-if="photoUrl">
                    </span>
                    <span class="ava-file__label">Прикрепить фото</span>
                  </div>
                </label>
              </div>

              <div class="col col--half">
                <div class="form-group">
                  <label for="name" class="label">ФИО <span v-if="dataChanged['name']" class="q-note q-note--saved">Сохранено</span></label>
                  <div class="form-field form-field--text form-field--2 form-field--m form-field--primary"
                       :class="{ 'has-error': !form.name && touched }">
                    <input id="name" @change="saveChanges" v-model="form.name" class="form-control" required>
                  </div>
                </div>
              </div>

              <div class="col col--half">
                <div class="form-group">
                  <label for="theme" class="label">Тема <span v-if="dataChanged['theme']" class="q-note q-note--saved">Сохранено</span></label>
                  <div class="form-field form-field--select form-field--2 form-field--m form-field--primary"
                       :class="{ 'has-error': !form.theme && touched }">
                    <select id="theme" ref="theme" v-model="form.theme" @change="selectTheme" class="form-control">
                      <option data-placeholder="true">Выберите тему</option>
                      <option v-for="theme in themes" :value="theme.id">{{ theme.name }}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col col--half">
                <div class="form-group">
                  <label for="phone" class="label">Телефон <span v-if="dataChanged['phone']"
                                                                 class="q-note q-note--saved">Сохранено</span></label>
                  <div :class="{ 'has-error': !form.phone && touched }"
                       class="form-field form-field--text form-field--2 form-field--m form-field--primary">
                    <input id="phone" @change="saveChanges" v-model="form.phone" type="tel"
                           class="form-control phone-mask">
                  </div>
                </div>
              </div>

              <div class="col col--half">
                <div class="form-group">
                  <label for="email" class="label">E-mail <span v-if="dataChanged['email']"
                                                                class="q-note q-note--saved">Сохранено</span></label>
                  <div :class="{ 'has-error': !form.email && touched }"
                       class="form-field form-field--text form-field--2 form-field--m form-field--primary">
                    <input id="email" @change="saveChanges" v-model="form.email" type="text"
                           class="form-control email-mask">
                  </div>
                </div>
              </div>

              <div class="w-100"></div>

              <div class="col col--half">
                <div class="form-group">
                  <label for="password" class="label">Пароль для входа <span v-if="dataChanged['password']"
                                                                             class="q-note q-note--saved">Сохранено</span></label>
                  <div class="form-field form-field--text form-field--2 form-field--m form-field--primary">
                    <input id="password" @change="saveChanges" v-model="form.password" autocomplete="nope"
                           :type="showPassword ? 'text' : 'password'" class="form-control">
                  </div>
                </div>
              </div>

              <div class="col col--half">
                <button @click="generateRandomPassword(16)" class="btn btn--transparent btn--icon" type="button">
                  <span class="icon icon--m">
                    <svg class="svg" width="24" height="24">
                      <use :href="'/img/sprite-' + appVersion + '.svg#security-24'"/>
                    </svg>
                  </span>Сгенерировать безопасный пароль
                </button>
              </div>
            </div>
          </div>

          <hr>

          <div class="content__block">
            <div class="i-title i-title--gap-bottom i-title--sm">
                <span class="i-title__icon">
                  <svg class="svg" width="24" height="24">
                    <use :href="'/img/sprite-' + appVersion + '.svg#mail-24'"/>
                  </svg>
                </span>
              <span class="i-title__label">Уведомления по электронной почте</span>
            </div>

            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="switch toggle toggle--m">
                    <input id="new_request" type="checkbox" class="switch__input" v-model="form.new_request"
                           @change="saveChanges">
                    <span class="switch__cover toggle__cover"></span>
                    <span class="switch__label toggle__label">Новые задачи</span>
                  </label>
                </div>
              </div>

              <div class="col">
                <div class="form-group">
                  <label class="switch toggle toggle--m">
                    <input id="new_request_comment" type="checkbox" class="switch__input"
                           v-model="form.new_request_comment" @change="saveChanges">
                    <span class="switch__cover toggle__cover"></span>
                    <span class="switch__label toggle__label">Новые комментарии</span>
                  </label>
                </div>
              </div>

              <div class="col">
                <div class="form-group">
                  <label class="switch toggle toggle--m">
                    <input id="request_change_status" type="checkbox" class="switch__input"
                           v-model="form.request_change_status" @change="saveChanges">
                    <span class="switch__cover toggle__cover"></span>
                    <span class="switch__label toggle__label">Новые статусы задач</span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <hr>

          <div class="content__block">
            <div class="i-title i-title--gap-bottom i-title--sm">
                <span class="i-title__icon">
                  <svg class="svg-clr" width="24" height="32">
                    <use :href="'/img/sprite-clr-' + appVersion + '.svg#telegram-24'"/>
                  </svg>
                </span>
              <span class="i-title__label">Уведомления в Telegram</span>
            </div>

            <div class="row">
              <template v-if="telegramBotName && !telegramUser">
                <div class="col">
                  <div class="text text--sm">Для получения уведомлений и создания задач через Telegram необходимо авторизоваться и разрешить доступ к данным</div>
                </div>

                <div class="col">
                  <div class="form-group">
                    <span v-if="!isTelegramButtonLoaded" class="switch__label toggle__label">Идёт загрузка скрипта Telegram</span>
                    <telegram-login-temp
                        mode="callback"
                        :telegram-login="telegramBotName"
                        @loaded="telegramButtonLoaded"
                        @callback="telegramLogin"
                    />
                  </div>
                </div>
              </template>

              <template v-if="telegramUser">
                <div class="col">
                  <div class="text text--sm">Вы авторизованы в Telegram как <strong>{{ telegramUser.first_name }} {{ telegramUser.last_name }}</strong>.
                    Запустите чат-бот, чтобы начать получать уведомления.
                  </div>
                </div>

                <div class="col">
                  <a :href="'https://t.me/' + telegramBotName" target="_blank" class="btn btn--1 btn--m btn--primary">
                      <span class="btn__icon">
                        <span class="icon icon--s">
                          <svg class="svg" width="16" height="16">
                            <use :href="'/img/sprite-' + appVersion + '.svg#telegram-16'"/>
                          </svg>
                        </span>
                      </span>
                    <span class="btn__label">Запустить чат-бот</span>
                  </a>
                </div>

                <div class="col">
                  <div class="form-group">
                    <label class="switch toggle toggle--m">
                      <input id="telegram_new_request" type="checkbox" class="switch__input"
                             v-model="form.telegram_new_request" @change="saveChanges">
                      <span class="switch__cover toggle__cover"></span>
                      <span class="switch__label toggle__label">Новые задачи</span>
                    </label>
                  </div>
                </div>

                <div class="col">
                  <div class="form-group">
                    <label class="switch toggle toggle--m">
                      <input id="telegram_new_request_comment" type="checkbox" class="switch__input"
                             v-model="form.telegram_new_request_comment" @change="saveChanges">
                      <span class="switch__cover toggle__cover"></span>
                      <span class="switch__label toggle__label">Новые комментарии</span>
                    </label>
                  </div>
                </div>

                <div class="col">
                  <div class="form-group">
                    <label class="switch toggle toggle--m">
                      <input id="telegram_request_change_status" type="checkbox" class="switch__input"
                             v-model="form.telegram_request_change_status" @change="saveChanges">
                      <span class="switch__cover toggle__cover"></span>
                      <span class="switch__label toggle__label">Новые статусы задач</span>
                    </label>
                  </div>
                </div>

                <div class="col">
                  <div class="text text--sm">В любой момент вы можете <a @click="deleteTelegramUser()" style="cursor: pointer" class="link link--main">
                    <strong>отвязать текущий аккаунт и удалить авторизационные данные</strong></a>.
                  </div>
                </div>
              </template>

              <div class="col">
                <div v-if="error" class="login-box__message login-box__message_left">
                  <span class="form-error" v-html="error"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content__bottom content__bottom--bt">
        <div class="btns-wrap btns-wrap--sb">
          <button @click="cancel()" class="btn btn--2 btn--m btn--primary" type="reset">
            <span class="btn__label">Отмена</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import packageInfo from '../../../../package';
import SlimSelect from 'slim-select';
import {Cropper} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import {telegramLoginTemp} from 'vue3-telegram-login';

export default {
  components: {
    Cropper, telegramLoginTemp,
  },
  props: {
    profile: Object,
  },
  data() {
    return {
      appVersion: packageInfo.version,
      error: null,
      showPassword: false,
      form: {
        name: null,
        theme: null,
        phone: null,
        email: null,
        password: null,
        new_request: null,
        new_request_comment: null,
        request_change_status: null,
        telegram_new_request: null,
        telegram_new_request_comment: null,
        telegram_request_change_status: null,
      },
      dataChanged: {},
      touched: null,
      init: null,
      themes: [{id: 'light', name: 'Светлая'}, {id: 'dark', name: 'Тёмная'}, {id: 'system', name: 'Системная'}],
      themeSelect: null,
      photoUrl: null,
      photoToUpload: null,
      timer: null,
      imageToCrop: null,
      isTelegramButtonLoaded: false,
      telegramBotName: null,
      telegramUser: null,
    };
  },
  mounted() {
    if (process.env.TELEGRAM_BOT_NAME) {
      this.telegramBotName = process.env.TELEGRAM_BOT_NAME;
    }
    this.themeSelect = new SlimSelect({
      select: this.$refs['theme'],
      settings: {
        showSearch: false,
        allowDeselect: false,
      },
    });
    if (this.profile.theme) {
      this.form.theme = this.themes.find(x => x.id == this.profile.theme).id;
      this.themeSelect.setSelected(this.form.theme);

      if (this.profile.theme === 'system' && window.matchMedia &&
          window.matchMedia('(prefers-color-scheme: dark)').matches) {
        document.body.classList.add('theme-dark');
      }
    } else {
      this.form.theme = this.themes[2];
    }
    this.profile.photo ? this.photoUrl = this.profile.photo : null;
    this.profile.name ? this.form.name = this.profile.name : null;
    this.profile.phone ? this.form.phone = this.profile.phone : null;
    this.profile.email ? this.form.email = this.profile.email : '';
    this.profile.new_request ? this.form.new_request = this.profile.new_request : null;
    this.profile.new_request_comment ? this.form.new_request_comment = this.profile.new_request_comment : null;
    this.profile.request_change_status ? this.form.request_change_status = this.profile.request_change_status : null;
    this.profile.telegram_new_request ? this.form.telegram_new_request = this.profile.telegram_new_request : null;
    this.profile.telegram_new_request_comment ?
        this.form.telegram_new_request_comment = this.profile.telegram_new_request_comment :
        null;
    this.profile.telegram_request_change_status ?
        this.form.telegram_request_change_status = this.profile.telegram_request_change_status :
        null;
    this.profile.telegramUser ? this.telegramUser = this.profile.telegramUser : null;
    this.$nextTick(function() {
      this.init = false;
    });
  },
  methods: {
    telegramButtonLoaded() {
      this.isTelegramButtonLoaded = true;
    },
    telegramLogin(user) {
      this.error = null;
      if (user) {
        let formData = new FormData;
        for (var key in user) {
          formData.append(key, user[key]);
        }
        axios.post('/telegram-users', formData).then(() => {
          axios.get('/telegram-users').then((res) => {
            this.telegramUser = res.data;
          });
        }).catch((err) => {
          this.error = err.response.data.message;
        });
      }
    },
    deleteTelegramUser() {
      axios.delete('/telegram-users', this.profile.id).then((res) => {
        this.telegramUser = null;
      });
    },
    cancel() {
      location.href = '/requests';
    },
    saveChanges(e) {
      if (this.init || !e) {
        return;
      }
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.dataChanged = {};
        let formData = new FormData;
        if (this.form[e.target.id]) {
          formData.append(e.target.id, this.form[e.target.id]);
        }
        this.submit(formData, e.target.id);
        this.touched = true;
      }, 200);
    },
    selectTheme(e) {
      if (this.init) {
        return;
      }
      this.theme = e;
      if (this.form.theme === 'light' || (this.form.theme === 'system' && window.matchMedia &&
          !window.matchMedia('(prefers-color-scheme: dark)').matches)) {
        document.body.classList.remove('theme-dark');
      } else if (this.form.theme === 'dark' || (this.form.theme === 'system' && window.matchMedia &&
          window.matchMedia('(prefers-color-scheme: dark)').matches)) {
        document.body.classList.add('theme-dark');
      }
      this.dataChanged = {};
      let formData = new FormData;
      formData.append('theme', this.form.theme);
      this.submit(formData, 'theme');
    },
    cropSelectedImage() {
      const {canvas} = this.$refs.cropper.getResult();
      if (canvas) {
        this.dataChanged = {};

        let formData = new FormData;
        canvas.toBlob(blob => {
          this.photoUrl = canvas.toDataURL();
          formData.append('photo', this.photoToUpload);
          formData.append('photo_cropped', blob);
          this.imageToCrop = null;
          this.submit(formData, 'photo');
        });
      }
    },
    uploadPhoto(event) {
      const {files} = event.target;
      if (files && files[0]) {
        this.photoToUpload = files[0];
        if (this.imageToCrop) {
          URL.revokeObjectURL(this.imageToCrop);
        }
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (_event) => {
          this.imageToCrop = URL.createObjectURL(files[0]);
        };
      }
    },
    generateRandomPassword(length) {
      var chars = 'abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890';
      var pass = '';
      for (var x = 0; x < length; x++) {
        var i = Math.floor(Math.random() * chars.length);
        pass += chars.charAt(i);
      }
      this.form.password = pass;
      this.showPassword = true;

      this.dataChanged = {};
      let formData = new FormData;
      formData.append('password', this.form.password);
      this.submit(formData, 'password');
    },
    submit(formData, field) {
      if (this.form.name) {
        formData.append('name', this.form.name);
        formData.append('email', this.form.email);
        if (this.form.new_request) {
          formData.append('new_request', this.form.new_request);
        }
        if (this.form.new_request_comment) {
          formData.append('new_request_comment', this.form.new_request_comment);
        }
        if (this.form.request_change_status) {
          formData.append('request_change_status', this.form.request_change_status);
        }
        if (this.form.telegram_new_request) {
          formData.append('telegram_new_request', this.form.telegram_new_request);
        }
        if (this.form.telegram_new_request_comment) {
          formData.append('telegram_new_request_comment', this.form.telegram_new_request_comment);
        }
        if (this.form.telegram_request_change_status) {
          formData.append('telegram_request_change_status', this.form.telegram_request_change_status);
        }
        axios.post('/profile', formData).then(() => {
          this.dataChanged[field] = true;
        }).catch((err) => {
          this.error = err.response.data.error;
        });
      }
    },
  },
};
</script>