<template>
  <form class="serv-tbl__subitem serv-tbl__subitem--add">
    <div class="serv-tbl__row">
      <div class="serv-tbl__col">
        <div class="form-group">
          <div class="form-field form-field--text form-field--2 form-field--m form-field--primary"
               :class="{ 'has-error': !form.name && touched }">
            <input v-model="form.name" @keyup="validate" type="text" class="form-control" placeholder="Название подраздела">
          </div>
        </div>
      </div>
      <div class="serv-tbl__col">
        <div class="form-group" data-suffix="мин">
          <div class="form-field form-field--text form-field--2 form-field--m form-field--primary"
               :class="{ 'has-error': !form.reaction_time && touched }">
            <input v-model="form.reaction_time" @keyup="validate" type="number" min="0" step="5" class="form-control">
          </div>
        </div>
      </div>
      <div class="serv-tbl__col">
        <div class="form-group" data-suffix="мин">
          <div class="form-field form-field--text form-field--2 form-field--m form-field--primary"
               :class="{ 'has-error': !form.decision_time && touched }">
            <input v-model="form.decision_time" @keyup="validate" type="number" min="0" step="5" class="form-control">
          </div>
        </div>
      </div>
      <div class="serv-tbl__col">
        <div class="form-group">
          <div class="form-field form-field--text form-field--2 form-field--m form-field--primary">
            <input v-model="form.description" @keyup="validate" type="text" class="form-control" placeholder="Описание">
          </div>
        </div>
      </div>
      <div class="serv-tbl__col">
        <button @click="hide" class="btn btn--2 btn--m btn--primary" type="button">
          <span class="btn__label">Отмена</span>
        </button>
      </div>
      <div class="serv-tbl__col">
        <button @click="submit" :disabled="!isValid" class="btn btn--1 btn--m btn--primary" type="button">
          <span class="btn__label">Сохранить</span>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    subService: Object,
    parent_id: Number
  },
  data() {
    return {
      isValid: false,
      form: {
        name: null,
        reaction_time: null,
        decision_time: null,
        description: null,
      },
      touched: false
    }
  },
  mounted() {
    if (this.subService) {
      this.subService.name ? this.form.name = this.subService.name : null;
      this.subService.reaction_time ? this.form.reaction_time = this.subService.reaction_time : null;
      this.subService.decision_time ? this.form.decision_time = this.subService.decision_time : null;
      this.subService.description ? this.form.description = this.subService.description : null;
      this.isValid = true;
    }
  },
  methods: {
    validate() {
      this.isValid = false;
      this.touched = true;
      let isEmpty = false;
      for (var key in this.form) {
        if (key === 'description') {
          continue;
        }
        if (this.form[key] == null || this.form[key] === "") {
          isEmpty = true;
        }
      }
      if (!isEmpty) {
        this.isValid = true;
      }
    },
    clearForm() {
      var props = Object.getOwnPropertyNames(this.form);
      for (var i = 0; i < props.length; i++) {
        delete this.form[props[i]];
      }
    },
    hide() {
      this.$emit('hide');
      this.clearForm();
    },
    submit() {
      this.form.parent_id = this.parent_id;
      this.$emit('submit', this.form);
      // this.clearForm();
    }
  }
};
</script>