<template>
  <div class="header__wrap">
    <div class="header__logo">
      <a href="/requests" class="logo">
        <img src="/img/logo-icon.svg" width="37" height="41" alt="" class="logo__icon">
        <svg class="logo__label" width="93" height="24">
          <use :href="'/img/sprite-' + appVersion + '.svg#logo-label'" />
        </svg>
      </a>
    </div>

    <div class="header__main">
      <div class="header__task">
        <div class="q-task">
          <div class="q-task__form">
            <input @keyup.enter="showRequestFormModal" type="text" v-model="taskName" class="q-task__input" placeholder="Новая задача">
            <button @click="showRequestFormModal" class="q-task__button" type="button">
              <span class="icon icon--m">
                <svg class="svg" width="24" height="24">
                  <use :href="'/img/sprite-' + appVersion + '.svg#arrow-right-filled-24'" />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="header__extra">
      <div class="header__profile">
        <div class="h-profile">
          <a href="/profile" class="h-profile__visual">
            <img :src="user.photo" width="32" height="32" alt="">
          </a>
          <div class="h-profile__ctrl">
            <button @click="logout" class="h-profile__btn" type="button" aria-label="Выход">
              <span class="icon icon--m">
                <svg class="svg" width="24" height="24">
                  <use :href="'/img/sprite-' + appVersion + '.svg#exit-24'" />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <request-form-modal v-if="isShowRequestFormModal" :createData="createData" :taskName="taskName"
                        @closeModal="closeShowRequestFormModal"></request-form-modal>
  </div>
</template>

<script>
import packageInfo  from '../../../../package';
import axios from 'axios';
import VueCookies from 'vue-cookies';
import RequestFormModal from '../Requests/RequestFormModal.vue';

export default {
  components: {RequestFormModal},
  props: {
    user: Object
  },
  data() {
    return {
      appVersion: packageInfo.version,
      authToken: null,
      isShowRequestFormModal: false,
      createData: null,
      taskName: null,
      project_id: null
    }
  },
  mounted() {
    this.authToken = VueCookies.get('jwt-token');
    const urlParams = new URLSearchParams(window.location.search);
    this.project_id = urlParams.get('project_id');
  },
  methods: {
    showRequestFormModal() {
      let url = '/requests/create';
      if (this.project_id) {
        url += '?project_id=' + this.project_id;
      }
      axios.get(url).then((res) => {
        this.createData = res.data;
        this.isShowRequestFormModal = true;
      })
    },
    closeShowRequestFormModal() {
      this.taskName = null;
      this.isShowRequestFormModal = false;
    },
    logout() {
      axios.get('/auth/logout', { headers: {'Authorization' : 'Bearer ' + this.authToken} }).then(() => {
        VueCookies.remove('jwt-token');
        location.href = '/login';
      })
    }
  }
}
</script>