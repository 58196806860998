export function focusInput() {
  const Modifier = {
    FOCUS: '--focus',
  };

  const formFieldClassName = 'form-field';
  const formFieldElements = document.querySelectorAll(`.${formFieldClassName}`);

  if (formFieldElements) {
    formFieldElements.forEach((element) => {
      const formControl = element.querySelector('.form-control');

      if (formControl) {
        formControl.addEventListener('focus', () => {
          element.classList.add(`${formFieldClassName}${Modifier.FOCUS}`);
        });

        formControl.addEventListener('focusout', () => {
          element.classList.remove(`${formFieldClassName}${Modifier.FOCUS}`);
        });
      }
    });
  }
}