<template>
  <div class="main__wrap">
    <div class="main__content content">
      <div class="content__top">
        <div class="form-group">
          <label for="name" class="form-label">Название каталога</label>
          <div class="form-field form-field--text form-field--2 form-field--l form-field--primary"
               :class="{ 'has-error': !form.catalog.name && touched }">
            <input id="name" v-model="form.catalog.name" @keyup="validate" type="text" class="form-control" placeholder="">
          </div>
        </div>
      </div>
      <div class="content__main">
        <div class="content__body">
          <div class="serv-tbl">
            <div class="serv-tbl__head">
              <div class="serv-tbl__row">
                <div class="serv-tbl__col">Услуга и описание</div>
                <div class="serv-tbl__col">Время реакции</div>
                <div class="serv-tbl__col">Время решения</div>
              </div>
            </div>
            <div class="serv-tbl__body">
              <div class="serv-tbl__list">
                <div v-for="(service, i) in form.catalog.services" class="serv-tbl__item" :class="{ 'open': form.catalog.services[i].isShow }">
                  <div @click="form.catalog.services[i].isShow = !form.catalog.services[i].isShow" class="serv-tbl__top">
                    <div class="serv-tbl__row">
                      <div class="serv-tbl__col">
                        <span class="serv-tbl__toggle"></span>
                        <span class="serv-tbl__title">{{service.name}}</span>
                        <button @click="showEditServiceForm(i)" class="serv-tbl__delete ibtn ibtn--gray" type="button">
                          <svg class="svg" width="16" height="16">
                            <use :href="'/img/sprite-' + appVersion + '.svg#setting-24'"></use>
                          </svg>
                        </button>
                        <button @click="deleteService(i)" class="serv-tbl__delete ibtn ibtn--gray" type="button">
                          <svg class="svg" width="16" height="16">
                            <use :href="'/img/sprite-' + appVersion + '.svg#delete-24'"/>
                          </svg>
                        </button>
                      </div>
                      <div class="serv-tbl__col">{{service.reaction_time}} мин</div>
                      <div class="serv-tbl__col">{{service.decision_time}} мин</div>
                    </div>
                  </div>
                  <div v-if="service.isShowEditServiceForm" class="serv-tbl__item">
                    <div class="serv-tbl__subitem serv-tbl__subitem--add">
                      <service :service="service" @submit="editService($event, i, service)" @hide="service.isShowEditServiceForm = false">
                      </service>
                    </div>
                  </div>
                  <div v-if="form.catalog.services[i].isShow" class="serv-tbl__more">
                    <div class="serv-tbl__sublist">
                      <div v-for="(subService, j) in service.subServices" class="serv-tbl__subitem">
                        <div class="serv-tbl__row">
                          <div class="serv-tbl__col">
                            <div class="serv-tbl__subtitle">{{subService.name}}
                              <button @click="subService.isShowEditSubServiceForm = !subService.isShowEditSubServiceForm"
                                      style="margin: 0 5px" class="serv-tbl__delete ibtn ibtn--gray" type="button">
                                <svg class="svg" width="16" height="16">
                                  <use :href="'/img/sprite-' + appVersion + '.svg#setting-24'"/>
                                </svg>
                              </button>
                              <button @click="deleteSubService(i, j)" class="serv-tbl__delete ibtn ibtn--gray" type="button">
                                <svg class="svg" width="16" height="16">
                                  <use :href="'/img/sprite-' + appVersion + '.svg#delete-24'"/>
                                </svg>
                              </button>
                            </div>
                            <div class="serv-tbl__description">{{subService.description}}</div>
                          </div>
                          <div class="serv-tbl__col">
                            <span class="serv-tbl__time">{{subService.reaction_time}} мин</span>
                          </div>
                          <div class="serv-tbl__col">
                            <span class="serv-tbl__time">{{subService.decision_time}} мин</span>
                          </div>
                        </div>
                        <subservice v-if="subService.isShowEditSubServiceForm" :subService="subService"
                                    :parent_id="form.catalog.services[i].id"
                                    @submit="editSubService($event, i, j)"
                                    @hide="subService.isShowEditSubServiceForm = false">
                        </subservice>
                      </div>
                      <subservice v-if="form.catalog.services[i].isShowAddSubServiceForm"
                                  :parent_id="form.catalog.services[i].id" @submit="addSubService($event, i)"
                                  @hide="form.catalog.services[i].isShowAddSubServiceForm = false">
                      </subservice>
                      <div v-if="!form.catalog.services[i].isShowAddSubServiceForm" class="serv-tbl__subitem serv-tbl__subitem--btn">
                        <a @click="form.catalog.services[i].isShowAddSubServiceForm = true"
                           style="cursor: pointer" class="btn btn--4 btn--m btn--primary">
                          <span class="btn__label">+ Добавить подраздел</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="isShowAddService" class="serv-tbl__item">
                  <div class="serv-tbl__top">
                    <service @submit="addService" @hide="isShowAddService = false">
                    </service>
                  </div>
                </div>
                <div class="serv-tbl__item serv-tbl__item--btn">
                  <a @click="isShowAddService = true" style="cursor: pointer" class="btn btn--4 btn--m btn--primary">
                    <span class="btn__label">+ Добавить услугу</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content__bottom content__bottom--bt">
        <div class="form-group">
          <label for="projects-select" class="form-label">Каталог услуг действует для следующих проектов</label>

          <div class="form-field form-field--select form-field--2 form-field--m form-field--primary">
            <select id="projects-select" ref="projects" :class="{ 'has-error': !form.catalog.projects.length && touched }"
                    v-model="form.catalog.projects" class="form-control" multiple>
              <option data-placeholder="true">Выберите проект</option>
              <option v-for="project in projects" :value="project.id">{{project.name}}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="content__bottom content__bottom--bt">
        <div class="btns-wrap btns-wrap--sb">
          <button @click="addCatalog" :disabled="!isValid" class="btn btn--1 btn--m btn--primary" type="button">
            <span class="btn__label">Добавить</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import packageInfo  from '../../../../package';
import Service from './Service.vue';
import Subservice from './Subservice.vue';
import SlimSelect from 'slim-select';

export default {
  components: {Subservice, Service},
  props: {
    projects: Array
  },
  data() {
    return {
      appVersion: packageInfo.version,
      isValid: false,
      form: {
        catalog: {
          name: null,
          projects: [],
          services: []
        }
      },
      touched: false,
      isShowAddService: false
    }
  },
  mounted() {
    new SlimSelect({
      select: this.$refs['projects'],
      settings: {
        showSearch: false,
        allowDeselect: false
      }
    });
  },
  methods: {
    validate() {
      this.isValid = false;
      this.touched = true;
      let isEmpty = false;
      for (var key in this.form) {
        if (this.form[key] == null || this.form[key] === "") {
          isEmpty = true;
        }
      }
      if (!isEmpty) {
        this.isValid = true;
      }
    },
    addService(event) {
      let service = {};
      service['name'] = event.name;
      service['reaction_time'] = event.reaction_time;
      service['decision_time'] = event.decision_time;
      service['subServices'] = [];
      service['isShow'] = true;
      service['isShowAddSubServiceForm'] = false;
      this.form.catalog.services.push(service);
      this.isShowAddService = false;
    },
    showEditServiceForm(i) {
      this.form.catalog.services[i].isShowEditServiceForm = !this.form.catalog.services[i].isShowEditServiceForm;
      this.form.catalog.services[i].isShow = false;
    },
    editService(event, i, oldService) {
      let service = event;
      service['isShowEditServiceForm'] = false;
      this.form.catalog.services[i] = service;
      this.form.catalog.services[i].subServices = oldService.subServices;
      this.form.catalog.services[i].isShow = true;
      this.form.catalog.services[i].isShowAddSubServiceForm = false;
    },
    deleteService(i) {
      this.form.catalog.services.splice(i, 1);
    },
    addSubService(event, i) {
      let subService = event;
      subService['isShowEditSubServiceForm'] = false;
      this.form.catalog.services[i].subServices.push(subService);
      this.form.catalog.services[i].isShowAddSubServiceForm = false;
    },
    editSubService(event, i, j) {
      this.form.catalog.services[i].subServices[j] = event;
      this.form.catalog.services[i].isShowAddSubServiceForm = false;
    },
    deleteSubService(i, j) {
      this.form.catalog.services[i].subServices.splice(j, 1);
    },
    addCatalog() {
      this.isFormSubmitted = true;
      if (this.form.catalog.name) {
        let formData = new FormData();
        formData.append('catalog', JSON.stringify(this.form.catalog));

        axios.post('/settings/catalogs', formData).then(() => {
          location.href = '/settings/catalogs';
        }).catch((err) => {
          this.error = err.response.data.error;
        });
      }
    }
  },
};
</script>