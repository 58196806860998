<template>
  <div class="main__wrap">
    <div class="main__content content">
      <div class="content__top">
        <div class="form-group">
          <label for="name" class="form-label">Название каталога <span v-if="dataChanged['name']" class="q-note q-note--saved">Сохранено</span></label>
          <div class="form-field form-field--text form-field--2 form-field--l form-field--primary"
               :class="{ 'has-error': !form.catalog.name && touched }">
            <input id="name" v-model="form.catalog.name" @change="updateName" type="text" class="form-control" placeholder="">
          </div>
        </div>
      </div>
      <div class="content__main">
        <div class="content__body">
          <div class="serv-tbl">
            <div class="serv-tbl__head">
              <div class="serv-tbl__row">
                <div class="serv-tbl__col">Услуга и описание</div>
                <div class="serv-tbl__col">Время реакции</div>
                <div class="serv-tbl__col">Время решения</div>
              </div>
            </div>
            <div class="serv-tbl__body">
              <div class="serv-tbl__list">
                <div v-for="(service, i) in form.catalog.services" class="serv-tbl__item" :class="{ 'open': form.catalog.services[i].isShow }">
                  <div @click="form.catalog.services[i].isShow = !form.catalog.services[i].isShow" class="serv-tbl__top">
                    <div class="serv-tbl__row">
                      <div class="serv-tbl__col">
                        <span class="serv-tbl__toggle"></span>
                        <span class="serv-tbl__title">{{service.name}}</span>
                        <button @click="showEditServiceForm(i)" class="serv-tbl__delete ibtn ibtn--gray" type="button">
                          <svg class="svg" width="16" height="16">
                            <use :href="'/img/sprite-' + appVersion + '.svg#setting-24'"></use>
                          </svg>
                        </button>
                        <button @click="deleteService(i)" class="serv-tbl__delete ibtn ibtn--gray" type="button">
                          <svg class="svg" width="16" height="16">
                            <use :href="'/img/sprite-' + appVersion + '.svg#delete-24'"/>
                          </svg>
                        </button>
                      </div>
                      <div class="serv-tbl__col">{{service.reaction_time}} мин</div>
                      <div class="serv-tbl__col">{{service.decision_time}} мин</div>
                    </div>
                  </div>
                  <div v-if="service.isShowEditServiceForm" class="serv-tbl__item">
                    <div class="serv-tbl__subitem serv-tbl__subitem--add">
                      <service :service="service" @submit="editService($event, i)" @hide="service.isShowEditServiceForm = false">
                      </service>
                    </div>
                  </div>
                  <div v-if="form.catalog.services[i].isShow" class="serv-tbl__more">
                    <div class="serv-tbl__sublist">
                      <div v-for="(subService, j) in service.sub_services" class="serv-tbl__subitem">
                        <div class="serv-tbl__row">
                          <div class="serv-tbl__col">
                            <div class="serv-tbl__subtitle">{{subService.name}}
                              <button @click="subService.isShowEditSubServiceForm = !subService.isShowEditSubServiceForm"
                                      style="margin: 0 5px" class="serv-tbl__delete ibtn ibtn--gray" type="button">
                                <svg class="svg" width="16" height="16">
                                  <use :href="'/img/sprite-' + appVersion + '.svg#setting-24'"/>
                                </svg>
                              </button>
                              <button @click="deleteSubService(i, j)" class="serv-tbl__delete ibtn ibtn--gray" type="button">
                                <svg class="svg" width="16" height="16">
                                  <use :href="'/img/sprite-' + appVersion + '.svg#delete-24'"/>
                                </svg>
                              </button>
                            </div>
                            <div class="serv-tbl__description">{{subService.description}}</div>
                          </div>
                          <div class="serv-tbl__col">
                            <span class="serv-tbl__time">{{subService.reaction_time}} мин</span>
                          </div>
                          <div class="serv-tbl__col">
                            <span class="serv-tbl__time">{{subService.decision_time}} мин</span>
                          </div>
                        </div>
                        <subservice v-if="subService.isShowEditSubServiceForm" :subService="subService"
                                    :parent_id="form.catalog.services[i].id"
                                    @submit="editSubService($event, subService.id)"
                                    @hide="subService.isShowEditSubServiceForm = false">
                        </subservice>
                      </div>
                      <subservice v-if="form.catalog.services[i].isShowAddSubServiceForm"
                                  :parent_id="form.catalog.services[i].id" @submit="addSubService($event, form.catalog.services[i].id)"
                                  @hide="form.catalog.services[i].isShowAddSubServiceForm = false">
                      </subservice>
                      <div v-if="!form.catalog.services[i].isShowAddSubServiceForm" class="serv-tbl__subitem serv-tbl__subitem--btn">
                        <a @click="form.catalog.services[i].isShowAddSubServiceForm = true"
                           style="cursor: pointer" class="btn btn--4 btn--m btn--primary">
                          <span class="btn__label">+ Добавить подраздел</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="isShowAddService" class="serv-tbl__item">
                  <div class="serv-tbl__top">
                    <service @submit="addService($event)" @hide="isShowAddService = false">
                    </service>
                  </div>
                </div>
                <div class="serv-tbl__item serv-tbl__item--btn">
                  <a @click="isShowAddService = true" style="cursor: pointer" class="btn btn--4 btn--m btn--primary">
                    <span class="btn__label">+ Добавить услугу</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content__bottom content__bottom--bt">
        <div class="form-group">
          <label for="projects-select" class="form-label">Каталог услуг действует для следующих проектов <span v-if="dataChanged['projects']" class="q-note q-note--saved">Сохранено</span></label>

          <div class="form-field form-field--select form-field--2 form-field--m form-field--primary"
               :class="{ 'has-error': !form.catalog.projects.length && touched }">
            <select id="projects-select" ref="projects" v-model="form.catalog.projects" @change="updateProjects" class="form-control" multiple>
              <option data-placeholder="true">Выберите проект</option>
              <option v-for="project in projects" :value="project.id">{{project.name}}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="content__bottom content__bottom--bt">
        <div class="btns-wrap btns-wrap--sb">
          <button @click="deleteCatalog" class="ibtn ibtn--gray ibtn--bordered" type="button">
            <svg class="svg" width="24" height="24">
              <use :href="'/img/sprite-' + appVersion + '.svg#delete-24'"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import packageInfo  from '../../../../package';
import Service from './Service.vue';
import Subservice from './Subservice.vue';
import SlimSelect from 'slim-select';
import axios from 'axios';

export default {
  components: {Subservice, Service},
  props: {
    catalog: Object,
    projects: Array
  },
  data() {
    return {
      appVersion: packageInfo.version,
      isValid: true,
      form: {
        catalog: {
          name: null,
          projects: [],
          services: []
        }
      },
      touched: false,
      isShowAddService: false,
      dataChanged: {},
      timer: null,
      init: true
    }
  },
  mounted() {
    this.form.catalog = this.catalog;
    for (let i = 0; i < this.form.catalog.services; i++) {
      this.form.catalog.services[i].isShow = false;
    }
    new SlimSelect({
      select: this.$refs['projects'],
      settings: {
        showSearch: false,
        allowDeselect: false
      }
    });
    this.$nextTick(function() {
      this.init = false;
    });
  },
  methods: {
    getCatalog() {
      axios.get('/settings/catalogs/' + this.catalog.id).then((res) => {
        this.form.catalog = res.data;
      });
    },
    updateName() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.dataChanged = {};
        let formData = new FormData();
        formData.append('name', this.form.catalog.name);
        this.validate();
        if (this.isValid) {
          this.updateCatalog(formData, 'name');
        }
      }, 500);
    },
    updateProjects() {
      if (!this.init) {
        this.dataChanged = {};
        let formData = new FormData();
        formData.append('name', this.form.catalog.name);
        formData.append('projects', JSON.stringify(this.form.catalog.projects));
        this.updateCatalog(formData, 'projects');
      }
    },
    updateCatalog(formData, field) {
      formData.append('_method', 'PUT');
      axios.post('/settings/catalogs/' + this.catalog.id, formData).then(() => {
        this.dataChanged[field] = true;
      }).catch((err) => {
        this.error = err.response.data.error;
      });
    },
    validate() {
      this.isValid = false;
      this.touched = true;
      let isEmpty = false;
      for (var key in this.form) {
        if (key === 'description') {
          continue;
        }
        if (this.form[key] == null || this.form[key] === "") {
          isEmpty = true;
        }
      }
      if (!isEmpty) {
        this.isValid = true;
      }
    },
    addService(service) {
      service.catalog_id = this.catalog.id;
      axios.post('/services', service).then(() => {
        this.getCatalog();
        this.isShowAddService = false;
      }).catch((err) => {
        this.error = err.response.data.error;
      });
    },
    showEditServiceForm(i) {
      this.form.catalog.services[i].isShowEditServiceForm = !this.form.catalog.services[i].isShowEditServiceForm;
      this.form.catalog.services[i].isShow = false;
    },
    editService(service, i) {
      service.catalog_id = this.catalog.id;
      axios.put('/services/' + this.form.catalog.services[i].id, service).then(() => {
        this.getCatalog();
      }).catch((err) => {
        this.error = err.response.data.error;
      });
    },
    deleteService(i) {
      if (confirm("Вы уверены, что хотите удалить услугу " + this.form.catalog.services[i].name + "?")) {
        axios.delete('/services/' + this.form.catalog.services[i].id).then(() => {
          this.getCatalog();
        }).catch((err) => {
          this.error = err.response.data.error;
        });
      }
    },
    addSubService(subService, id) {
      subService.catalog_id = this.catalog.id;
      subService.service_id = id;
      axios.post('/create-subservice', subService).then(() => {
        this.getCatalog();
      }).catch((err) => {
        this.error = err.response.data.error;
      });
    },
    editSubService(subService, subService_id) {
      let formData = new FormData();

      for (let [key, value] of Object.entries(subService)) {
        if (value) {
          formData.append(key, value.toString());
        }
      }
      formData.append('catalog_id', this.catalog.id.toString());
      formData.append('_method', 'PUT');
      axios.post('/update-subservice/' + subService_id, formData).then(() => {
        this.getCatalog();
      }).catch((err) => {
        this.error = err.response.data.error;
      });
    },
    deleteSubService(i, j) {
      if (confirm("Вы уверены, что хотите удалить подраздел услуги " + this.form.catalog.services[i].sub_services[j].name + "?")) {
        axios.delete('/services/' + this.form.catalog.services[i].sub_services[j].id).then(() => {
          this.getCatalog();
        }).catch((err) => {
          this.error = err.response.data.error;
        });
      }
    },
    deleteCatalog() {
      if (confirm("Вы уверены, что хотите удалить каталог " + this.form.catalog.name + "?")) {
        axios.delete('/settings/catalogs/' + this.catalog.id).then(() => {
          location.href = '/settings/catalogs';
        }).catch((err) => {
          this.error = err.response.data.error;
        });
      }
    },
  }
};
</script>