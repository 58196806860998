<template>
  <div v-if="!isShowCode" class="login-box__form">
    <div class="login-box__block">
      <div class="form-group form-group--center">
        <label for="login" class="form-label">Номер телефона</label>
        <div class="form-field form-field--text form-field--2 form-field--m form-field--primary"
             v-bind:class="{ 'has-error': !form.phone_number && touched }">
          <input id="login" ref="login" v-model="form.phone_number" @keyup="validate" @keyup.enter="sendCode"
                 type="text" class="form-control phone-mask" placeholder="Введите телефон">
        </div>
      </div>
    </div>

    <div class="login-box__submit">
      <button v-if="!sendSuccess" :disabled="!isPhoneNumberValid" @click="sendCode" type="button"
              class="btn btn--1 btn--m btn--primary">
        <span class="btn__label">Отправить код</span>
      </button>
      <div v-if="sendSuccess">Код отравлен. Повторная отправка станет возможна через {{timer}} сек</div>
    </div>

    <div v-if="sendDisabled" class="login-box__message">
      <span class="form-error">Не введён номер телефона</span>
    </div>
    <div v-if="errorPhone" class="login-box__message">
      <span class="form-error">{{errorPhone}}</span>
    </div>
  </div>
  <div v-if="isShowCode" class="login-box__form">
    <div class="login-box__block">
      <div class="form-group form-group--center">
        <label for="code" class="form-label">СМС-код</label>
        <div id="code" class="form-field form-field--text form-field--2 form-field--m form-field--primary"
             v-bind:class="{ 'has-error': !form.code && touched }">
          <input id="code" ref="code" type="text" v-model="form.code" @keyup="validate" @keyup.enter="submit"
                 class="form-control sms-mask" placeholder="Введите код">
        </div>
      </div>
    </div>

    <div v-if="errorCode" class="login-box__message">
      <span class="form-error">{{errorCode}}</span>
    </div>

    <div class="login-box__submit">
      <button @click="submit" :disabled="!isValid" class="btn btn--1 btn--m btn--primary" type="button">
        <span class="btn__label">Войти</span>
      </button>
    </div>

    <div class="login-box__bottom">
      <button @click="hideCode" class="btn btn--4 btn--m btn--primary" type="button">
        <span class="btn__label">Назад</span>
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Inputmask from 'inputmask/lib/inputmask';
import {focusInput} from '../_Functions/focusInput';

export default {
  props: {
    errorCode: String
  },
  data() {
    return {
      isPhoneNumberValid: false,
      isValid: false,
      isShowCode: false,
      errorPhone: null,
      form: {
        phone_number: null,
        code: null,
      },
      touched: false,
      sendSuccess: false,
      sendDisabled: false,
      timer: 30
    };
  },
  mounted() {
    this.phoneMask();
    focusInput();
    this.$refs['login'].focus();
  },
  methods: {
    phoneMask() {
      const phones = document.querySelectorAll(
          '.phone-mask');
      if (phones) {
        phones.forEach((element) => {
          Inputmask({'mask': '+7 (999) 999 9999'}).mask(element);
        });
      }
    },
    smsCodeMask() {
      const sms = document.querySelectorAll(
          '.sms-mask');
      if (sms) {
        sms.forEach((element) => {
          Inputmask({'mask': '9999'}).mask(element);
        });
      }
    },
    decrement() {
      if (this.timer > 0) {
        this.timer -= 1;
      } else if (this.timer === 0) {
        this.sendSuccess = false;
        this.sendDisabled = false;
      }
    },
    validate() {
      this.isPhoneNumberValid = false;
      let phone_number = this.form.phone_number.replace(/[^0-9]/g, '');

      if (phone_number && phone_number.length === 11) {
        this.isPhoneNumberValid = true;

        if (this.form.code) {
          let code = this.form.code.replace(/[^0-9]/g, '');
          if (code.length === 4) {
            this.isValid = true;
          }
        }
      }
    },
    sendCode() {
      this.sendSuccess = false;
      this.sendDisabled = false;
      this.errorPhone = false;
      let phone_number = this.form.phone_number.replace(/[^0-9]/g, '');

      if (phone_number && phone_number.length === 11) {
        this.isPhoneNumberValid = true;
        axios.post('/send-login-code', {'phone_number': phone_number})
        .then(() => {
          this.sendSuccess = true;
          this.isShowCode = true;
          this.timer = 30;
          setInterval(() => this.decrement(), 1000);

          setTimeout(() => {
            this.$refs['code'].focus();
            this.smsCodeMask();
          }, '250');
        }).catch((err) => {
          this.errorPhone = err.response.data.error;
        });
      }
    },
    hideCode() {
      this.isShowCode = false;

      setTimeout(() => {
        this.$refs['login'].focus();
        this.phoneMask();
      }, '250');
    },
    submit() {
      if (this.isValid) {
        let phone_number = this.form.phone_number.replace(/[^0-9]/g, '');
        let code = this.form.code.replace(/[^0-9]/g, '');
        if (phone_number && phone_number.length === 11 && code.length === 4) {
          this.$emit('submit', this.form)
        }
      }
    },
  },
};
</script>